<template>
  <b-row class="match-height">
    <b-col lg="8">
      <EditBasicSetting />
    </b-col>
    <b-col lg="4">
      <Sidebar />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EditBasicSetting from '@/components/company/basicSetting/EditBasicSetting.vue'
import Sidebar from '@/components/company/sidebar/test/Sidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    EditBasicSetting,
    Sidebar,
  },
}
</script>
